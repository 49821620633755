import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { FaMedium } from "react-icons/fa6";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";

import Me from "../assets/me.webp";

const Hero = () => {
  return (
    <div className="relative z-20 pt-[85px]" id="home">
      <div
        className="inset-0 py-10 pr-10 pl-20 lg:pl-32 flex flex-col md:grid md:grid-cols-[70fr,30fr] justify-center"
        style={{ minHeight: "calc(100vh - 85px)" }}
      >
        <div className="flex flex-col justify-center">
          <div className="text-white">
            <h1 className="text-3xl sm:text-5xl md:text-6xl font-bold ">
              <div className="pb-3">Hi There,</div>
              I'm <span className="text-[#ff7919]">Binisha Joshi!</span>
            </h1>
            <p className="text-3xl mt-3 font-medium">
              I am a{" "}
              <TypeAnimation
                sequence={[
                  "Backend Developer",
                  1500,
                  "Node.js Developer",
                  1500,
                ]}
                speed={20}
                repeat={Infinity}
                style={{ color: "#09B260" }}
              />
            </p>
          </div>
          <div className="mt-5 flex ">
            <Link
              to="https://www.linkedin.com/in/binisha-joshi"
              target="_blank"
            >
              <div className="group bg-white max-w-fit p-3 rounded-full mr-3 hover:bg-[#0A66C2] hover:cursor-pointer">
                <FaLinkedin className="text-[#309b67] text-2xl group-hover:text-gray-50" />
              </div>
            </Link>

            <Link to="https://github.com/BinishaJ" target="_blank">
              <div className="group bg-white max-w-fit p-3 rounded-full mr-3 hover:bg-black hover:cursor-pointer">
                <FaGithub className="text-[#309b67] text-2xl group-hover:text-gray-50" />
              </div>
            </Link>

            <Link to="https://medium.com/@binishaj" target="_blank">
              <div className="group bg-white max-w-fit p-3 rounded-full hover:bg-black hover:cursor-pointer">
                <FaMedium className="text-[#309b67] text-2xl group-hover:text-gray-50" />
              </div>
            </Link>
          </div>
        </div>
        <div className="flex items-center mt-8 md:mt-0">
          <LazyLoadImage
            src={Me}
            effect="blur"
            alt="Me"
            className="rounded-full w-[150px] h-[150px] md:w-[200px] md:h-[200px] lg:w-[280px] lg:h-[280px]"
            style={{
              userDrag: "none",
              userSelect: "none",
              MozUserSelect: "none",
              WebkitUserDrag: "none",
              WebkitUserSelect: "none",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
