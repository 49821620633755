import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import NotFoundError from "../assets/NotFound.webp";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <img
        src={NotFoundError}
        alt="Not Found"
        draggable="false"
        className="w-[90vw] md:w-[70vw] lg:w-[50vw] h-4/5 py-5"
      />
      <Link to="/">
        <button className="flex items-center text-lg bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-[400ms] shadow-[0_0_10px_0_rgba(0,110,0,0.85)] hover:shadow-[0_0_10px_0_rgba(0,110,0,1)] px-6 py-3">
          <FaChevronLeft className="mr-2" />
          Back to Home
        </button>
      </Link>
    </div>
  );
};

export default NotFound;
