import About from "./About.jsx";
import Education from "./Education.jsx";
import HeaderHero from "./Header";
import Projects from "./Projects.jsx";
import Skills from "./Skills.jsx";

const Home = () => {
  return (
    <>
      <HeaderHero />
      <About />
      <Skills />
      <Education />
      <Projects />
    </>
  );
};

export default Home;
