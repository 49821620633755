import React from "react";
import { FaChevronRight, FaUser } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";

import Me from "../assets/about-me.webp";

const About = () => {
  return (
    <div
      className="pb-10 pt-[85px] px-14 lg:px-20 flex flex-col items-center min-h-screen"
      id="about"
    >
      <div className="flex">
        <FaUser className="text-3xl mr-3 text-[#ff7919]" />
        <h1 className="text-3xl font-bold mb-12 md:mb-20">
          About <span className="text-[#09B260]">Me</span>
        </h1>
      </div>
      <div className="md:grid grid-cols-[40fr,60fr] md:max-w-4xl mb-4 ">
        <div className="flex justify-center w-[15rem] h-[10rem] sm:w-[18rem] sm:h-[12rem] md:w-[24rem] md:h-[24rem] lg:h-auto md:mr-14 lg:mr-[100px] max-h-[400px] mb-10 md:mb-0 mx-auto opacity-90 hover:opacity-100 hover:translate-y-1 hover:translate-x-1 transition-all duration-300 ">
          <LazyLoadImage
            src={Me}
            effect="blur"
            alt="Me"
            className="rounded-xl hover:cursor-pointer shadow-[0_0_20px_3px_rgba(38,38,38,0.4)] hover:shadow-[0_0_20px_3px_rgba(38,38,38,0.5)]"
          />
        </div>

        <div>
          <h2 className="text-3xl font-bold mb-2">I'm Binisha Joshi</h2>
          <h3 className="text-lg font-bold mb-3 text-[#ff7919]">
            Backend Developer
          </h3>
          <p className="lg:text-[1.1rem] md:leading-loose">
            I am a BSc. CSIT graduate from St. Xavier's College, Maitighar and a
            Backend Developer based in Kathmandu, Nepal. I am working as an
            Associate Software Engineer with little experience in frontend
            development with a passion for backend development and problem
            solving with a specialty in MERN stack and Python.
          </p>
          <Link to="/CV.pdf" target="_blank">
            <button className="flex items-center px-7 py-[0.8rem] text-lg my-10 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-[400ms] group shadow-[0_0_10px_0_rgba(0,110,0,0.85)] hover:shadow-[0_0_10px_0_rgba(0,110,0,1)]">
              Resume
              <FaChevronRight className="group-hover:ml-3 ml-1 transition-all duration-500" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;
