import React from "react";
import { GiGraduateCap } from "react-icons/gi";
import education from "./education.json";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Education = () => {
  return (
    <div
      className="pb-10 px-14 pt-[85px] lg:px-20 flex flex-col items-center bg-[#dbe4f1] min-h-screen"
      id="education"
    >
      <div className="flex mb-10">
        <GiGraduateCap className="text-4xl mr-4 text-[#682800]" />
        <h1 className="text-4xl font-bold text-[#00964d]">Education</h1>
      </div>
      <div className="w-full sm:max-w-[85%]">
        {education.map((e, index) => (
          <div
            key={index}
            className="flex flex-col md:grid md:grid-cols-[30fr,70fr] bg-white mb-8 rounded-lg shadow-[5px_5px_10px_-5px_rgba(0,0,0,0.3)] hover:shadow-[5px_7px_15px_-2px_rgba(0,0,0,0.3)] transition-all duration-300"
          >
            <LazyLoadImage
              src={require(`../assets/${e.image}`)}
              effect="blur"
              alt={e.name}
              style={{
                userDrag: "none",
                userSelect: "none",
                MozUserSelect: "none",
                WebkitUserDrag: "none",
                WebkitUserSelect: "none",
              }}
              className="h-full rounded-t-lg md:rounded-r-none md:rounded-l-lg w-full"
            />
            <div className="mx-6 my-5 md:my-7">
              <h1 className="text-lg sm:text-2xl font-bold mt-1 mb-2 text-[#00964d]">
                {e.degree}
              </h1>
              <h1 className="text-base sm:text-[1.05rem] mt-1 tracking-wide">
                {e.institution}
              </h1>
              <h1 className="text-base sm:text-lg font-medium mt-1 text-[#c05000]">
                {e.year}
              </h1>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Education;
