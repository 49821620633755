import React, { useState } from "react";
import { IoClose, IoMenu } from "react-icons/io5";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";

import Hero from "./Hero";

const Header = () => {
  const [menu, setMenu] = useState(false);
  const links = ["home", "about", "skills", "education", "projects"];

  return (
    <div
      className="bg-cover bg-center min-h-screen"
      style={{ backgroundImage: `url(${require("../assets/hero.webp")})` }}
    >
      {menu && (
        <div className="z-[100] w-3/4 sm:w-[280px] bg-black text-gray-50 block md:hidden inset-0 fixed pt-12 h-screen">
          <ul className="uppercase px-10 py-7">
            {links.map((link) => (
              <Link
                key={link}
                onClick={() => {
                  window.scrollTo({
                    top: document.getElementById(link).offsetTop,
                    behavior: "smooth",
                  });
                  setMenu(!menu);
                }}
              >
                <li className="border-b border-gray-400 text-lg my-4 pb-2">
                  {link}
                </li>
              </Link>
            ))}
          </ul>
        </div>
      )}
      <div className="flex py-5 px-9 items-center backdrop-blur-[18px] bg-opacity-40 fixed w-full top-0 z-50 h-[85px] ">
        <div className="absolute inset-0 bg-[#094e2d] opacity-30 backdrop-blur-md -z-10"></div>
        <Link
          to="/"
          onClick={() => {
            window.scrollTo({
              top: document.getElementById("home").offsetTop,
              behavior: "smooth",
            });
          }}
        >
          <LazyLoadImage
            src={logo}
            effect="blur"
            alt="logo"
            className="w-[70px] "
          />
        </Link>

        <div className="flex flex-1 justify-end font-medium text-lg ">
          {!menu ? (
            <IoMenu
              className="md:hidden text-3xl text-gray-100"
              onClick={() => setMenu(!menu)}
            />
          ) : (
            <IoClose
              className="md:hidden text-3xl text-gray-100"
              onClick={() => setMenu(!menu)}
            />
          )}

          {links.map((link) => (
            <Link
              key={link}
              className="mr-10 text-gray-100 group hidden md:block capitalize"
              onClick={() => {
                window.scrollTo({
                  top: document.getElementById(link).offsetTop,
                  behavior: "smooth",
                });
                setMenu(!menu);
              }}
            >
              {link}
            </Link>
          ))}
        </div>
      </div>
      <div className="relative">
        <Hero />
        <div className="absolute inset-0 bg-black opacity-60 h-full top-0 left-0 w-full"></div>
      </div>
    </div>
  );
};

export default Header;
// #ff7919 #09B260
