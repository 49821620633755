import { FaLaptopCode } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

import projects from "./projects.json";

const Projects = () => {
  return (
    <div
      className="pb-10 pt-[85px] px-14 lg:px-6 flex flex-col items-center min-h-screen bg-[#3696c7] text-white"
      id="projects"
    >
      <div className="flex text-4xl mb-12 items-end">
        <FaLaptopCode className="mr-3" />
        <h1 className="font-bold ">My Projects</h1>
      </div>

      <div className="w-full sm:max-w-[85%] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-3 gap-x-9">
        {projects.map((p, index) => (
          <Link
            to={p.link}
            target="_blank"
            rel="noreferrer noopener"
            key={index}
            className="flex flex-col bg-white mb-8 rounded-lg shadow-[5px_5px_10px_-5px_rgba(0,0,0,0.3)] hover:shadow-[5px_7px_15px_-2px_rgba(0,0,0,0.3)]"
          >
            <div className="relative group min-h-full">
              <LazyLoadImage
                src={require(`../assets/projects/${p.image}`)}
                alt={p.description}
                effect="blur"
                style={{
                  userDrag: "none",
                  userSelect: "none",
                  MozUserSelect: "none",
                  WebkitUserDrag: "none",
                  WebkitUserSelect: "none",
                }}
                wrapperClassName="h-full"
                className="rounded-lg object-cover object-left w-full h-full min-h-[300px]"
              />
              <div className="absolute bottom-0 w-full">
                <p className="bg-[#f7a014] text-lg font-bold p-3 rounded-b-lg rounded-t-lg group-hover:rounded-b-none">
                  {p.title}
                </p>
                <p className="h-0 px-5 rounded-b-lg bg-[#fad291] overflow-hidden group-hover:h-full group-hover:py-3">
                  {p.description}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Projects;
