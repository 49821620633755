import React from "react";
import { FaCode } from "react-icons/fa";
import skills from "./skills.json";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Skills = () => {
  return (
    <div
      className="pb-10 pt-[85px] px-14 lg:px-20 bg-[#f8944b] min-h-screen"
      id="skills"
    >
      <div className="flex justify-center mb-14">
        <FaCode className="text-[#47ff6a] text-5xl mr-3" />
        <h1 className="text-white text-5xl font-bold">Skills</h1>
      </div>
      <div className="max-w-80 bg-[#ff7919] rounded-md p-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {skills.map((skill, index) => (
          <div
            key={index}
            className="flex flex-col items-center bg-white m-3 p-2 rounded-md"
          >
            <LazyLoadImage
              src={skill.icon}
              alt={skill.name}
              effect="blur"
              style={{
                userDrag: "none",
                userSelect: "none",
                MozUserSelect: "none",
                WebkitUserDrag: "none",
                WebkitUserSelect: "none",
              }}
            />
            <h1 className="text-lg font-medium mt-1">{skill.name}</h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skills;
